/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import * as THREE from "three";

const audio = new Audio("/thefrontier.mp3");

export default function Beer(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF(
        "/shefii1.glb"
    );



    const { actions } = useAnimations(animations, group);
      const animationsArray = Object.keys(actions);
    React.useEffect(() => {
       // actions && actions['NlaTrack'].play();
    }, [actions]);


    const playAudio = () => {
        audio.currentTime = 0;
        audio.play();
    }


    const playRandomAnimation = () => {
        // Ensure no animation is currently playing
        const isAnyAnimationPlaying = Object.values(actions).some(action => action.isRunning());

        if (!isAnyAnimationPlaying) {
            audio.currentTime = 0;
            // Stop all animations to ensure a clean state
            Object.values(actions).forEach(action => action.stop());

            // Select and play a random animation
            const animationsArray = Object.keys(actions);
            const randomAnimation = animationsArray[Math.floor(Math.random() * animationsArray.length)];
            console.log("randomAnimation: ", randomAnimation);
            const action = actions[randomAnimation];
            action.reset();
            action.play();
            audio.play();
            action.clampWhenFinished = true;
            action.loop = THREE.LoopOnce;

            // Speed up the animation - adjust this value as needed
            action.timeScale = 5; // This will play the animation at double speed
        }
    };


    return (
        <group ref={group} onClick={()=>{playAudio()}}  {...props} dispose={null}>
                <group name="Scene">
                    <group
                        name="Empty_(Animation)"
                        position={[-0.036, 2.782, 0]}
                        rotation={[0, 10.7, 0]}
                        scale={[0.46, 2.964, 1.904]}>
                        <group name="Card" position={[-0.033, -0.38, 0]} scale={[0.377, 0.543, 0.845]}>
                            <mesh
                                name="Cube001"
                                castShadow
                                receiveShadow
                                geometry={nodes.Cube001.geometry}
                                material={materials['Card material']}
                            />
                            <mesh
                                name="Cube001_1"
                                castShadow
                                receiveShadow
                                geometry={nodes.Cube001_1.geometry}
                                material={materials['Glow 1']}
                            />
                        </group>
                        <mesh
                            name="Cube004"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube004.geometry}
                            material={materials['Element material ']}
                            position={[-0.441, -0.024, 0]}
                            rotation={[0, 0, -Math.PI]}
                            scale={[0.044, 0.006, 0.791]}
                        />
                        <mesh
                            name="Cube005"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube005.geometry}
                            material={materials['Element material ']}
                            position={[-0.375, -0.028, 0]}
                            rotation={[-Math.PI / 2, 0, -Math.PI]}
                            scale={[0.044, 0.009, 0.508]}
                        />
                        <mesh
                            name="Cylinder_ICON_2"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cylinder_ICON_2.geometry}
                            material={materials['Glow Text Behind']}
                            position={[0.342, 0.75, -0.638]}
                            rotation={[-Math.PI, 0, -Math.PI / 2]}
                            scale={[-0.049, -0.021, -0.077]}
                        />
                        <mesh
                            name="Cylinder002"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cylinder002.geometry}
                            material={materials['Glow ring material']}
                            position={[-0.376, -0.032, 0]}
                            rotation={[0, 0, -Math.PI / 2]}
                            scale={[0.337, 0.04, 0.525]}
                        />
                        <mesh
                            name="Cylinder003"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cylinder003.geometry}
                            material={materials['Element material ']}
                            position={[-0.368, 0.786, 0]}
                            rotation={[-0.021, -0.009, -1.571]}
                            scale={[0.401, 0.047, 0.624]}
                        />
                        <mesh
                            name="Cylinder004"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cylinder004.geometry}
                            material={materials['Element material ']}
                            position={[-0.368, -0.844, 0]}
                            rotation={[3.121, -0.009, -1.571]}
                            scale={[0.401, 0.047, 0.624]}
                        />
                        <mesh
                            name="Cylinder005"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cylinder005.geometry}
                            material={materials['Element material ']}
                            position={[-0.379, -0.222, 0]}
                            rotation={[3.121, -0.009, -1.571]}
                            scale={[0.415, 0.049, 0.645]}
                        />
                        <mesh
                            name="Cylinder006"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cylinder006.geometry}
                            material={materials['Element material ']}
                            position={[-0.38, 0.162, 0]}
                            rotation={[-0.021, -0.009, -1.571]}
                            scale={[0.415, 0.049, 0.645]}
                        />
                        <mesh
                            name="Line"
                            castShadow
                            receiveShadow
                            geometry={nodes.Line.geometry}
                            material={materials['Element line material']}
                            position={[0.345, -0.638, 0]}
                            rotation={[0, 0, -Math.PI]}
                            scale={[0.011, 0.003, 0.791]}
                        />
                        <mesh
                            name="Line_(Glow_animation)"
                            castShadow
                            receiveShadow
                            geometry={nodes['Line_(Glow_animation)'].geometry}
                            material={materials['Glow element (animation)']}
                            position={[0.345, -0.638, 0.653]}
                            rotation={[0, 0, -Math.PI]}
                            scale={[0.011, 0.003, 0.791]}
                        />
                        <mesh
                            name="Number"
                            castShadow
                            receiveShadow
                            geometry={nodes.Number.geometry}
                            material={materials['Glow Text Behind']}
                            position={[0.345, 0.757, 0.56]}
                            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                            scale={[0.216, 0.893, 0.139]}
                        />
                        <mesh
                            name="shefi2"
                            castShadow
                            receiveShadow
                            geometry={nodes.shefi2.geometry}
                            material={materials.shefi2}
                            position={[0.445, 0.226, -0.002]}
                            rotation={[1.569, 0.08, -1.53]}
                            scale={[1.302, 2.174, 0.784]}
                        />
                        <mesh
                            name="Text_Behind"
                            castShadow
                            receiveShadow
                            geometry={nodes.Text_Behind.geometry}
                            material={materials['Glow Text Behind']}
                            position={[-0.404, -0.023, 0]}
                            rotation={[Math.PI / 2, 0, Math.PI / 2]}
                            scale={[0.451, 1.867, 0.29]}
                        />
                        <mesh
                            name="Text_card_name"
                            castShadow
                            receiveShadow
                            geometry={nodes.Text_card_name.geometry}
                            material={materials['Glow Text Behind']}
                            position={[0.405, -0.773, 0.248]}
                            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                            scale={[0.18, 0.744, 0.116]}
                        />
                        <mesh
                            name="Text_Icon_2"
                            castShadow
                            receiveShadow
                            geometry={nodes.Text_Icon_2.geometry}
                            material={materials['Icon material']}
                            position={[0.362, 0.75, -0.641]}
                            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                            scale={[0.083, 0.345, 0.054]}
                        />
                        <mesh
                            name="Text002"
                            castShadow
                            receiveShadow
                            geometry={nodes.Text002.geometry}
                            material={materials['Glow Text Behind']}
                            position={[0.339, -0.393, 0.436]}
                            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                            scale={[0.216, 0.893, 0.139]}
                        />
                        <mesh
                            name="Text005"
                            castShadow
                            receiveShadow
                            geometry={nodes.Text005.geometry}
                            material={materials['Glow Text Behind']}
                            position={[0.437, -0.393, -0.348]}
                            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                            scale={[0.216, 0.893, 0.139]}
                        />
                        <mesh
                            name="Text006"
                            castShadow
                            receiveShadow
                            geometry={nodes.Text006.geometry}
                            material={materials['Glow Text Behind']}
                            position={[0.401, -0.533, 0.418]}
                            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                            scale={[0.094, 0.388, 0.06]}
                        />
                        <mesh
                            name="Text009"
                            castShadow
                            receiveShadow
                            geometry={nodes.Text009.geometry}
                            material={materials['Glow Text Behind']}
                            position={[0.422, -0.533, -0.37]}
                            rotation={[1.607, 0.013, -1.598]}
                            scale={[0.094, 0.388, 0.06]}
                        />
                    </group>
                </group>
        </group>
    );
}

useGLTF.preload("/shefii1.glb");